import React, { useEffect, useState } from "react";
import AdminSearch from "../../../Component/Admin/adminsearch";
import DoctorSearch from "../../../Component/Doctor/doctorsearch";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import BaseUrl from "../../../Api/baseurl";
import dayjs from "dayjs";

const EditAppointment = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    age: "",
    contact: "",
    email: "",
    city: "",
    location: "",
    date: dayjs().format("YYYY-MM-DD"),
    time: "",
    gender: "",
    department: "",
    doctor: "",
    problem: "",
    username: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [slots, setSlots] = useState({});
  const [timeslots, setTimeslots] = useState([]);
  const [existingBookings, setExistingBookings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [location, setLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [doctor, setDoctor] = useState("");
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isStaff, setIsStaff] = useState(false);

  useEffect(() => {
    if (id) {
      fetchAppointmentData(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedDate && doctor) {
      getData(selectedDate, doctor);
      fetchExistingBookings(selectedDate).then((bookings) =>
        setExistingBookings(bookings)
      );
    }
  }, [selectedDate, doctor]);

  useEffect(() => {
    if (formData.location) {
      getDepartments(formData.location);
    } else {
      setDepartments([]);
    }
  }, [formData.location]);

  useEffect(() => {
    const superuser = Cookies.get("is_superuser") === "true";
    const staff = Cookies.get("is_staff") === "true";
    setIsSuperuser(superuser);
    setIsStaff(staff);

    getLocation();
  }, []);

  const fetchAppointmentData = async (appointmentId) => {
    try {
      const response = await axios.get(
        `${BaseUrl}clinic/bookings-list/${appointmentId}/`
      );
      const data = response.data;
      setFormData({
        name: data.name,
        age: data.age,
        contact: data.contact,
        email: data.email,
        city: data.city,
        location: data.location,
        date: data.date,
        time: data.time,
        gender: data.gender,
        department: data.department,
        doctor: data.doctor,
        problem: data.problem,
        username: data.username,
      });
      setDoctor(data.username);
      setSelectedDate(data.date);
      setSelectedTime(data.time);
      getData(data.date, data.username).then(() => {
        setTimeslots((prevSlots) =>
          prevSlots.map((slot) => ({
            ...slot,
            active: slot.time === data.time,
          }))
        );
      });
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "warning",
          title: "Session expired. Please login again.",
        });
        Cookies.remove("token");
        Cookies.remove("username");
        Cookies.remove("is_superuser");
        Cookies.remove("is_staff");
        Cookies.remove("status");
        navigate("/admin/login");
      } else {
        Swal.fire({
          title: "Error!",
          text: `There was an issue fetching appointment data: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    if (name === "doctor") {
      handleDoctorChange(value);
    }
  };

  const handleDoctorChange = (doctorFullName) => {
    const doctorData = departments.find(
      (doctor) => `${doctor.fname} ${doctor.lname}` === doctorFullName
    );

    if (doctorData) {
      setFormData((prev) => ({
        ...prev,
        doctor: `${doctorData.fname} ${doctorData.lname}`,
        username: doctorData.username,
      }));
      setDoctor(doctorData.username);
      fetchExistingBookings(doctorData.username);
    }
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "name",
      "age",
      "contact",
      "email",
      "city",
      "location",
      "department",
      "doctor",
      "date",
      "time",
      "gender",
      "problem",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]?.trim()) {
        errors[field] = `Please enter the ${field}.`;
      }
    });

    if (formData.email && !isValidEmail(formData.email.trim())) {
      errors.email = "Please enter a valid email address.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Update it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        try {
          await axios.put(`${BaseUrl}clinic/bookings-list/${id}/`, formData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          navigate("/admin/appointments");
          Swal.fire({
            title: "Success!",
            text: "Appointment updated successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `There was an issue updating the appointment: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    }
  };

  const getData = async (slotdate, doctor) => {
    const apiUrl = `${BaseUrl}clinic/slots/${doctor}/${slotdate}`;
    const token = Cookies.get("token");
    try {
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Token ${token}` },
      });
      const info = response.data;
      if (!info || info.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Not Available",
          text: "No slots available on this date",
        });
        setSlots({});
        setTimeslots([]);
      } else {
        setSlots(info[0]);
        generateTimeSlots(info[0]);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getDepartments = async (location) => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get(`${BaseUrl}clinic/staff-list/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setDepartments(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const getLocation = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get(`${BaseUrl}clinic/managelocation/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setLocation(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const generateTimeSlots = (slotsData) => {
    const timeList = [];
    const {
      duration,
      morningS,
      morningE,
      afternoonS,
      afternoonE,
      eveningS,
      eveningE,
    } = slotsData;

    const addTimeSlots = (start, end) => {
      let currentTime = dayjs(start, "HH:mm");
      const endTime = dayjs(end, "HH:mm");
      while (currentTime.isBefore(endTime)) {
        timeList.push({
          time: currentTime.format("HH:mm"),
          booked: existingBookings.includes(currentTime.format("HH:mm")),
          active: currentTime.format("HH:mm") === formData.time,
        });
        currentTime = currentTime.add(duration, "minute");
      }
    };

    if (morningS && morningE) addTimeSlots(morningS, morningE);
    if (afternoonS && afternoonE) addTimeSlots(afternoonS, afternoonE);
    if (eveningS && eveningE) addTimeSlots(eveningS, eveningE);

    setTimeslots(timeList);
  };

  const handleDateChange = (e) => {
    const formattedDate = e.target.value;
    setSelectedDate(formattedDate);
    setSelectedTime("");
    setFormData((prevData) => ({ ...prevData, date: formattedDate }));
    setFormErrors((prevErrors) => ({ ...prevErrors, date: "" }));
  };

  const handleTimeClick = (time) => {
    if (existingBookings.includes(time)) return; // Prevent selecting booked slots
    setSelectedTime(time);
    setFormData((prev) => ({ ...prev, time }));
    setFormErrors((prevErrors) => ({ ...prevErrors, time: "" }));
    setTimeslots((prevSlots) =>
      prevSlots.map((slot) => ({
        ...slot,
        active: slot.time === time,
      }))
    );
  };

  const fetchExistingBookings = async (date) => {
    const apiUrl = `${BaseUrl}clinic/booking/${doctor}`;
    try {
      const response = await axios.get(apiUrl);
      const bookings = response.data.filter((booking) => booking.date === date);
      const bookedTimes = bookings.map((booking) => booking.time);
      setExistingBookings(bookedTimes);
      return bookedTimes;
    } catch (error) {
      setError(error.message);
      return [];
    }
  };

  return (
    <div className="py-8 px-8 w-full md:w-[80%] xl:w-full">
      {isSuperuser ? <AdminSearch /> : <DoctorSearch />}

      <div className="w-full bg-[#F2F2F2] px-4 py-8 mt-3">
        <div className="flex items-center justify-between">
          <text className="font-nunito-sans text-[32px] font-bold leading-[43.65px] text-[#202224]">
            Edit Appointment
          </text>
        </div>
        <div>
          <form id="AddBlog" onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-3">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="given-name"
                        value={formData.name}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.name && (
                        <p className="text-red-600">{formErrors.name}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="age"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Age<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="age"
                        id="age"
                        value={formData.age}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.age && (
                        <p className="text-red-600">{formErrors.age}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="contact"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contact<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="contact"
                        id="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.contact && (
                        <p className="text-red-600">{formErrors.contact}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.email && (
                        <p className="text-red-600">{formErrors.email}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="city"
                        id="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.city && (
                        <p className="text-red-600">{formErrors.city}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Location<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <select
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a location</option>
                        {location.length > 0 ? (
                          location
                            .filter((location) => location.status === 1) // Filter locations by status
                            .map((location) => (
                              <option
                                key={location.id}
                                value={formData.location.name}
                              >
                                {location.name}
                              </option>
                            ))
                        ) : (
                          <option disabled>Loading...</option>
                        )}
                      </select>
                      {formErrors.location && (
                        <p className="text-red-600">{formErrors.location}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="department"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Department<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      {/* <select
                        id="department"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a department</option>
                        {departments.length > 0 ? (
                          departments
                            .filter((departments) => departments.location === formData.location) // Filter locations by status
                            .map((departments) => (
                              <option
                                key={departments.id}
                                value={departments.department}
                              >
                                {departments.department}
                              </option>
                            ))
                        ) : (
                          <option disabled>Loading...</option>
                        )}
                      </select> */}
                      <select
                        id="department"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a department</option>
                        {departments.length > 0 ? (
                          Array.from(
                            new Set(
                              departments
                                .filter(
                                  (department) =>
                                    department.location === formData.location
                                )
                                .map((department) => department.department)
                            )
                          ).map((department) => {
                            const departmentData = departments.find(
                              (dep) =>
                                dep.department === department &&
                                dep.location === formData.location
                            );
                            return (
                              <option
                                key={departmentData.id}
                                value={departmentData.department}
                              >
                                {departmentData.department}
                              </option>
                            );
                          })
                        ) : (
                          <option disabled>Loading...</option>
                        )}
                      </select>

                      {formErrors.department && (
                        <p className="text-red-600">{formErrors.department}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="doctor"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Doctor<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <select
                        id="doctor"
                        name="doctor"
                        value={formData.doctor}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a Doctor</option>
                        {departments.length > 0 ? (
                          Array.from(
                            new Set(
                              departments
                                .filter(
                                  (doctor) =>
                                    doctor.department === formData.department
                                )
                                .map((doctor) => doctor.name)
                            )
                          ).map((doctorName) => {
                            const doctorData = departments.find(
                              (doc) =>
                                doc.location === formData.location &&
                                doc.department === formData.department
                            );
                            return doctorData ? (
                              <option
                                key={doctorData.id}
                                value={
                                  doctorData.fname + " " + doctorData.lname
                                }
                              >
                                {doctorData.fname + " " + doctorData.lname}
                              </option>
                            ) : null;
                          })
                        ) : (
                          <option disabled>Loading...</option>
                        )}
                      </select>
                      {formErrors.doctor && (
                        <p className="text-red-600">{formErrors.doctor}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Date<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="date"
                        id="date"
                        min={dayjs().format("YYYY-MM-DD")}
                        value={formData.date}
                        onChange={handleDateChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.date && (
                        <p className="text-red-600">{formErrors.date}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="gender"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Gender<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <select
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      {formErrors.gender && (
                        <p className="text-red-600">{formErrors.gender}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="problem"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Problem<span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      <textarea
                        name="problem"
                        id="problem"
                        rows="3"
                        value={formData.problem}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {formErrors.problem && (
                        <p className="text-red-600">{formErrors.problem}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="time"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Time<span className="text-red-500">*</span>
                    </label>
                    {/* <div className="mt-2">
                      <div className="grid grid-cols-3 gap-2">
                        {timeslots.map((slot) => (
                          <button
                            type="button"
                            key={slot.time}
                            className={`p-2 rounded-md ${
                              slot.booked
                                ? "bg-gray-400 cursor-not-allowed"
                                : slot.active
                                ? "bg-blue-500 text-white cursor-allowed"
                                : "bg-white text-gray-700 hover:ring-blue-500"
                            }`}
                            onClick={() => {
                              if (!slot.booked) {
                                handleTimeClick(slot.time);
                              }
                            }}
                            disabled={slot.booked}
                          >
                            {slot.time}
                          </button>
                        ))}
                      </div>
                      {formErrors.time && (
                        <p className="mt-2 text-red-600">{formErrors.time}</p>
                      )}
                    </div> */}

                    {/* <div className="mt-2">
                      <div className="grid grid-cols-3 gap-2">
                        {timeslots.map((slot) => (
                          <button
                            type="button"
                            key={slot.time}
                            className={`p-2 rounded-md transition-colors duration-300 ${
                              (slot.booked 
                                ? "bg-gray-400 cursor-not-allowed": "text-gray-600 cursor-pointer hover:text-white hover:bg-blue-500 focus:ring-2 focus:ring-blue-500")
                                || (slot.active
                                ? "!bg-blue-500 !text-white !cursor-pointer"
                                : "bg-white text-gray-700 hover:bg-blue-100 focus:ring-2 focus:ring-blue-500")
                            }`}
                            onClick={() => {
                              if (!slot.booked) {
                                handleTimeClick(slot.time);
                              }
                            }}
                            disabled={slot.booked}
                          >
                            {slot.time}
                          </button>
                        ))}
                      </div>
                    {formErrors.time && (
                        <p className="mt-2 text-red-600">{formErrors.time}</p>
                      )}
                    </div> */}

                    <div className="mt-2">
                      <div className="grid grid-cols-3 gap-2">
                        {timeslots.map((slot) => {
                          const baseClasses =
                            "p-2 rounded-md transition-colors duration-300";
                          const bookedClasses =
                            "!bg-gray-500 !cursor-not-allowed !text-white";
                          const availableClasses =
                            "bg-white text-gray-700 hover:bg-blue-100 focus:ring-2 focus:ring-blue-500";
                          const activeClasses =
                            "bg-blue-500 text-white cursor-pointer";

                          const buttonClasses = slot.booked
                            ? bookedClasses
                            : slot.active
                            ? activeClasses
                            : availableClasses;

                          return (
                            <button
                              type="button"
                              key={slot.time}
                              className={`${baseClasses} ${buttonClasses}`}
                              onClick={() => {
                                if (!slot.booked) {
                                  handleTimeClick(slot.time);
                                }
                              }}
                              disabled={slot.booked}
                            >
                              {slot.time}
                            </button>
                          );
                        })}
                      </div>
                      {formErrors.time && (
                        <p className="mt-2 text-red-600">{formErrors.time}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center gap-6">
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Save
                </button>
                <Link
                  to={
                    isSuperuser ? "/admin/appointments" : "/doctor/appointments"
                  }
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAppointment;
