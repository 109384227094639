// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";
// import { RiArrowDownSLine } from "react-icons/ri";
// import Cookies from "js-cookie";

// const AppointmentModal = () => {
//   const navigate = useNavigate();

//   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
//   const [formData, setFormData] = useState({
//     name: "",
//     age: "",
//     contact: "",
//     email: "",
//     city: "",
//     gender: "",
//   });
//   const [formErrors, setFormErrors] = useState({
//     name: "",
//     age: "",
//     contact: "",
//     email: "",
//     city: "",
//     gender: "",
//   });
//   const [modalOpen, setModalOpen] = useState(true);

//   const ageOptions = [
//     "Under 18",
//     "18-25",
//     "26-35",
//     "36-45",
//     "46-55",
//     "56-65",
//     "Over 65",
//   ];

//   const getModalInfo = () => {
//     if (
//       formErrors.name ||
//       formErrors.age ||
//       formErrors.phone ||
//       formErrors.email ||
//       formErrors.city ||
//       formErrors.gender ||
//       formErrors.message
//     ) {
//       return false;
//     } else {
//       return true;
//     }
//   };

//   const handleCaptchaChange = (value) => {
//     const captchaError = document.getElementById("captcha-error");
//     setIsCaptchaVerified(true);
//     captchaError.textContent = "";
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     setFormErrors({
//       ...formErrors,
//       [name]: "",
//     });
//   };

//   const validateForm = () => {
//     let isValid = true;
//     const errors = {
//       name: "",
//       age: "",
//       contact: "",
//       email: "",
//       city: "",
//       gender: "",
//     };

//     // Validating full name
//     if (!formData.name?.trim()) {
//       errors.name = "Please enter your full name.";
//       isValid = false;
//     }

//     // Validating age
//     if (!formData.age) {
//       errors.age = "Please select your age.";
//       isValid = false;
//     }

//     // Validating phone number
//     if (!formData.contact?.trim()) {
//       errors.contact = "Please enter your phone number.";
//       isValid = false;
//     } else if (formData.contact.trim().length !== 10) {
//       errors.contact = "Please enter 10-digit phone number.";
//       isValid = false;
//     }

//     // Validating email
//     if (!formData.email?.trim()) {
//       errors.email = "Please enter your email address.";
//       isValid = false;
//     } else if (!isValidEmail(formData.email.trim())) {
//       errors.email = "Please enter a valid email address.";
//       isValid = false;
//     }

//     // Validating city
//     if (!formData.city?.trim()) {
//       errors.city = "Please enter your city.";
//       isValid = false;
//     }

//     // Validating service
//     if (formData.gender === "") {
//       errors.gender = "Please select the gender.";
//       isValid = false;
//     }

//     // Validating message (optional)
//     // No specific validation needed for optional message field

//     setFormErrors(errors); // Set errors state to manage error messages

//     return isValid;
//   };

//   const [empty, setEmpty] = useState(false);

//   const handleSubmit = (e) => {
//     const captcha = document.getElementById("captcha").value;
//     const captchaError = document.getElementById("captcha-error");
//     captchaError.textContent = "";
//     if (!isCaptchaVerified) {
//       captchaError.textContent = "Please check the box to proceed";
//     }
//     e.preventDefault();

//     const isValid = validateForm();

//     setEmpty(false);

//     if (isValid) {
//       localStorage.setItem("formData", JSON.stringify(formData));
//       Cookies.set("name", formData.name);
//       setEmpty(true);

//       navigate("/booking"); // Navigate to booking page
//       window.location.reload();

//       setModalOpen(false); // Close the modal
//       // setFormData(formData); // Reset form data after successful submission
//       // setFormErrors(formErrors); // Clear form errors after successful submission
//     }
//   };

//   const isValidEmail = (email) => {
//     // Basic email validation regex
//     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//     return emailRegex.test(email);
//   };

//   const handleReset = () => {
//     // console.log("Before reset:", formData); // Check current state
//     // setFormData(initialFormData); // Reset form data
//     // console.log("After reset:", formData); // Check state after reset
//     // setFormErrors(initialFormErrors); // Clear form errors
//     const captchaError = document.getElementById("captcha-error");
//     captchaError.textContent = "";
//     setFormErrors("");
//     setFormData("");
//   };
//   return (
//     <>
//       {modalOpen && (
//         <div
//           className="modal fade"
//           id="exampleModal"
//           tabindex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog">
//             <div className="modal-content border-[#F16163] rounded-t-xl">
//               <div className="modal-header bg-[#F16163] text-white flex justify-center items-center rounded-t-xl  ">
//                 <h1 className="modal-title fs-5" id="exampleModalLabel">
//                   Book Your Appointment With Us
//                 </h1>
//                 <button
//                   type="button"
//                   class="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form
//                   id="appointmentForm"
//                   onSubmit={handleSubmit}
//                   //   onClick={()=>validateForm()}
//                   className="modal-body"
//                 >
//                   <div className="mb-3">
//                     <label htmlFor="fullName" className="form-label">
//                       Full Name<span className="text-red-600">*</span>
//                     </label>
//                     <input
//                       //   type="text"
//                       id="fullName"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                     />
//                     <span
//                       id="name-error"
//                       className="error-message text-red-500 mt-2 text-xs"
//                     >
//                       {" "}
//                       {formErrors.name}{" "}
//                     </span>
//                   </div>
//                   <div className=" grid grid-cols-3 gap-4 mb-2">
//                     <div>
//                       <label htmlFor="fullName" className="form-label">
//                         Age<span className="text-red-600">*</span>
//                       </label>
//                       <select
//                         id="age"
//                         name="age"
//                         value={formData.age}
//                         onChange={handleChange}
//                         className="form-select mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                       >
//                         <option
//                           value=""
//                           className="text-sm font-medium border-gray-300 "
//                         >
//                           Select Age<span className="text-red-600">*</span>
//                         </option>
//                         {ageOptions.map((option) => (
//                           <option
//                             key={option}
//                             value={option}
//                             className="text-sm font-medium "
//                           >
//                             {option}
//                           </option>
//                         ))}
//                       </select>
//                       <span
//                         id="age-error"
//                         className="error-message text-red-500 mt-2 text-xs"
//                       >
//                         {formErrors.age}
//                       </span>
//                     </div>
//                     <div className="col-span-2">
//                       <label htmlFor="contact" className="form-label">
//                         Phone Number<span className="text-red-600">*</span>
//                       </label>
//                       <input
//                         type="tel"
//                         id="contact"
//                         name="contact"
//                         value={formData.contact}
//                         onChange={handleChange}
//                         className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                       />
//                       <span
//                         id="phone-error"
//                         className="error-message text-red-500 mt-2 text-xs"
//                       >
//                         {formErrors.contact}
//                       </span>
//                     </div>
//                   </div>
//                   <div className="mb-2 mt-3">
//                     <label htmlFor="email" className="form-label">
//                       Email address<span className="text-red-600">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       id="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                     />
//                     <span
//                       id="email-error"
//                       className="error-message text-red-500 mt-2 text-xs"
//                     >
//                       {formErrors.email}
//                     </span>
//                   </div>
//                   <div className=" grid grid-cols-4 gap-4 mb-2 mt-3">
//                     <div className="col-span-2">
//                       <label htmlFor="fullName" className="form-label">
//                         City<span className="text-red-600">*</span>
//                       </label>
//                       <input
//                         type="text"
//                         id="city"
//                         name="city"
//                         value={formData.city}
//                         onChange={handleChange}
//                         className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                       />
//                       <span
//                         id="city-error"
//                         className="error-message text-red-500 mt-2 text-xs"
//                       >
//                         {formErrors.city}
//                       </span>
//                     </div>
//                     <div className="col-span-2">
//                       <label htmlFor="gender" className="form-label">
//                         Gender<span className="text-red-600">*</span>
//                       </label>
//                       <select
//                         id="gender"
//                         name="gender"
//                         value={formData.gender}
//                         onChange={handleChange}
//                         className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                       >
//                         <option value="">Select Gender </option>
//                         <option value="Male">Male</option>
//                         <option value="Female">Female</option>
//                         <option value="Other">Other</option>
//                       </select>
//                       <span
//                         id="gender-error"
//                         className="error-message text-red-500 mt-2 text-xs"
//                       >
//                         {formErrors.gender}
//                       </span>
//                     </div>
//                   </div>

//                   <div className="">
//                     {/* <label htmlFor="message" className="form-label">
//                       Other
//                     </label>
//                     <textarea
//                       id="message"
//                       name="other"
//                       rows="3"
//                       placeholder="Write your problem here in detail..."
//                       value={formData.other}
//                       onChange={handleChange}
//                       className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
//                     ></textarea> */}
                    

//                     <div className="scale-[0.8] flex flex-col ml-[-40px] mt-3 flex items-start w-full" >
//                       <ReCAPTCHA
//                         sitekey="6Lc4RyEqAAAAAKpyye27qavRHxgswURGIuebcTmE"
//                         onChange={handleCaptchaChange}
//                         id="captcha"
//                       />
//                       <span className="text-red-500 font-medium text-[16px] mt-1" id="captcha-error"></span>
//                     </div>
//                   </div>

//                   <div className="mb-3">
//                     <button
//                       data-bs-dismiss={empty && `modal`}
//                       aria-label={empty && `close`}
//                       type="submit"
//                       className="w-[130px] mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#F16163] text-base font-medium text-white hover:bg-red-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 md:ml-0 sm:text-sm"
//                       // class="btn-close"
//                     >
//                       Continue
//                     </button>
//                     <button
//                       type="reset"
//                       className=" w-[130px] mt-3  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:text-sm"
//                       onClick={() => handleReset()}
//                     >
//                       Reset
//                     </button>
//                   </div>
//                   <div className="modal-footer mt-4 flex flex-col justify-start items-start text-sm font-medium">
//                     <p>15+ Years of Surgical Experience. </p>
//                     <p> All Insurances Accepted</p>
//                     <p> EMI Facility Available at 0% Rate</p>
//                     <p> Get a free full-body check-up on your first booking</p>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default AppointmentModal;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";

const AppointmentModal = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    contact: "",
    email: "",
    city: "",
    gender: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    age: "",
    contact: "",
    email: "",
    city: "",
    gender: "",
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    const storedData = localStorage.getItem('formData');
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  const ageOptions = [
    "Under 18",
    "18-25",
    "26-35",
    "36-45",
    "46-55",
    "56-65",
    "Over 65",
  ];

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(true);
    document.getElementById("captcha-error").textContent = "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: "",
      age: "",
      contact: "",
      email: "",
      city: "",
      gender: "",
    };

    if (!formData.name.trim()) {
      errors.name = "Please enter your full name.";
      isValid = false;
    }

    if (!formData.age) {
      errors.age = "Please select your age.";
      isValid = false;
    }

    if (!formData.contact.trim()) {
      errors.contact = "Please enter your phone number.";
      isValid = false;
    } else if (formData.contact.trim().length !== 10) {
      errors.contact = "Please enter a 10-digit phone number.";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Please enter your email address.";
      isValid = false;
    } else if (!isValidEmail(formData.email.trim())) {
      errors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = "Please enter your city.";
      isValid = false;
    }

    if (formData.gender === "") {
      errors.gender = "Please select your gender.";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const captchaError = document.getElementById("captcha-error");
    captchaError.textContent = "";

    if (!isCaptchaVerified) {
      captchaError.textContent = "Please check the box to proceed";
      return;
    }

    const isValid = validateForm();
    if (isValid) {
      localStorage.setItem("formData", JSON.stringify(formData));
      Cookies.set("name", formData.name);
      navigate("/booking");
      setModalOpen(false);
      window.location.reload();
    }
  };

  const handleReset = () => {
    setFormData({
      name: "",
      age: "",
      contact: "",
      email: "",
      city: "",
      gender: "",
    });
    setFormErrors({
      name: "",
      age: "",
      contact: "",
      email: "",
      city: "",
      gender: "",
    });
    setIsCaptchaVerified(false);
    document.getElementById("captcha-error").textContent = "";
  };

  return (
    <>
      {modalOpen && (
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content border-[#F16163] rounded-t-xl">
              <div className="modal-header bg-[#F16163] text-white flex justify-center items-center rounded-t-xl">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Book Your Appointment With Us
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="appointmentForm"
                  onSubmit={handleSubmit}
                  className="modal-body"
                >
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Full Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <span
                      id="name-error"
                      className="error-message text-red-500 mt-2 text-xs"
                    >
                      {formErrors.name}
                    </span>
                  </div>
                  <div className="grid grid-cols-3 gap-4 mb-2">
                    <div>
                      <label htmlFor="age" className="form-label">
                        Age<span className="text-red-600">*</span>
                      </label>
                      <select
                        id="age"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        className="form-select mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="" className="text-sm font-medium">
                          Select Age<span className="text-red-600">*</span>
                        </option>
                        {ageOptions.map((option) => (
                          <option
                            key={option}
                            value={option}
                            className="text-sm font-medium"
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      <span
                        id="age-error"
                        className="error-message text-red-500 mt-2 text-xs"
                      >
                        {formErrors.age}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="contact" className="form-label">
                        Phone Number<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="tel"
                        id="contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      <span
                        id="phone-error"
                        className="error-message text-red-500 mt-2 text-xs"
                      >
                        {formErrors.contact}
                      </span>
                    </div>
                  </div>
                  <div className="mb-2 mt-3">
                    <label htmlFor="email" className="form-label">
                      Email address<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <span
                      id="email-error"
                      className="error-message text-red-500 mt-2 text-xs"
                    >
                      {formErrors.email}
                    </span>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-2 mt-3">
                    <div className="col-span-2">
                      <label htmlFor="city" className="form-label">
                        City<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      <span
                        id="city-error"
                        className="error-message text-red-500 mt-2 text-xs"
                      >
                        {formErrors.city}
                      </span>
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="gender" className="form-label">
                        Gender<span className="text-red-600">*</span>
                      </label>
                      <select
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="form-control mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      <span
                        id="gender-error"
                        className="error-message text-red-500 mt-2 text-xs"
                      >
                        {formErrors.gender}
                      </span>
                    </div>
                  </div>
                  <div className="scale-[0.8] flex flex-col ml-[-40px] mt-3 flex items-start w-full">
                    <ReCAPTCHA
                      sitekey="6Lc4RyEqAAAAAKpyye27qavRHxgswURGIuebcTmE"
                      onChange={handleCaptchaChange}
                      id="captcha"
                    />
                    <span className="text-red-500 font-medium text-[16px] mt-1" id="captcha-error"></span>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="w-[130px] mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#F16163] text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 md:ml-0 sm:text-sm"
                    >
                      Continue
                    </button>
                    <button
                      type="reset"
                      className="w-[130px] mt-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:text-sm"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </div>
                  <div className="modal-footer mt-4 flex flex-col justify-start items-start text-sm font-medium">
                    <p>15+ Years of Surgical Experience.</p>
                    <p>All Insurances Accepted</p>
                    <p>EMI Facility Available at 0% Rate</p>
                    <p>Get a free full-body check-up on your first booking</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmentModal;
