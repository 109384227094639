import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import CheckIcon from "@mui/icons-material/Check";
import dayjs from "dayjs";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import BaseUrl from "../Api/baseurl";
import LoaderH from "../Component/Loader/loader";

const Booking = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    date: selectedDate,
    time: "",
    location: "",
    department: "",
    doctor: "",
    problem: "",
    username: "",
  });
  const [formErrors, setFormErrors] = useState({
    date: "",
    time: "",
    location: "",
    department: "",
    doctor: "",
    problem: "",
    // username:""
  });
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState({});
  const [error, setError] = useState(null);
  const [timeslots, setTimeslots] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [existingBookings, setExistingBookings] = useState([]);
  const [location, setLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [doctor, setDoctor] = useState("");
  const [name, setName] = useState("");

  const getData = async (slotdate) => {
    const apiUrl = `${BaseUrl}clinic/slots/${doctor}/${slotdate}`;
    try {
      const response = await axios.get(apiUrl, {});
      const info = response.data;
      if (!info || info.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Not Available",
          text: "No slots available on this date",
        });
        setSlots({});
        setTimeslots([]);
      } else {
        setSlots(info[0]);
        generateTimeSlots(info[0]);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  

  const fetchExistingBookings = async (date) => {
    const apiUrl = `${BaseUrl}clinic/booking/${doctor}`;
    try {
      const response = await axios.get(apiUrl, {});
      return response.data.filter((booking) => booking.date === date);
    } catch (error) {
      setError(error);
      return [];
    }
  };
  const generateTimeSlots = (slotsData) => {
    const timeList = [];
    const {
      duration,
      morningS,
      morningE,
      afternoonS,
      afternoonE,
      eveningS,
      eveningE,
      username,
    } = slotsData;

    const addTimeSlots = (start, end) => {
      let currentTime = dayjs(start, "HH:mm");
      const endTime = dayjs(end, "HH:mm");
      while (currentTime.isBefore(endTime)) {
        timeList.push({ time: currentTime.format("HH:mm") });
        currentTime = currentTime.add(duration, "minute");
      }
    };

    if (morningS && morningE) {
      addTimeSlots(morningS, morningE);
    }
    if (afternoonS && afternoonE) {
      addTimeSlots(afternoonS, afternoonE);
    }
    if (eveningS && eveningE) {
      addTimeSlots(eveningS, eveningE);
    }

    setTimeslots(timeList);
  };

  useEffect(() => {
    if (formData.location) {
      getDepartments(formData.location);
    } else {
      setDepartments([]);
    }
  }, [formData.location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
    if (name === "doctor") {
      handleDoctorChange(value);
      formData.date = "";
    }
  };
  const handleDoctorChange = (user) => {
    // setSelectedDate(dayjs().format("YYYY-MM-DD"));

    const doctorData = departments.find((doctor) => doctor.username === user);

    if (doctorData) {
      setFormData((prev) => ({
        ...prev,
        doctor: doctorData.fname + " " + doctorData.lname,
        username: doctorData.username,
      }));
      setDoctor(doctorData.username);
    }
    setSelectedDate(null); 
    handleDateChange(null);
    setSelectedTime("")
  };

  const handleTimeClick = (time) => {
    if (existingBookings.some((booking) => booking.time === time)) return;
    setSelectedTime(time);
    setFormData((prev) => ({ ...prev, time: time }));
    setFormErrors((prevErrors) => ({ ...prevErrors, time: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.date) errors.date = "Date is required";
    if (!formData.time) errors.time = "Time is required";
    if (!formData.location) errors.location = "Location is required";
    if (!formData.department) errors.department = "Department is required";
    if (!formData.doctor) errors.doctor = "Doctor is required";
    if (!formData.problem) errors.problem = "Problem is required";

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }
    const formDatatosend = {
      ...data,
      ...formData,
    };

    try {
      setLoading(true);
      window.scrollTo(0, 0);
      await axios.post(`${BaseUrl}clinic/submit-appointment/`, formDatatosend);
      setLoading(false);
      Cookies.remove("name");
      localStorage.removeItem("formData");

      Swal.fire({
        title: "Success!",
        html: `
              <div style="text-align:left">
                <p style="font-weight:bold color:blue">Your appointment is scheduled for:</p>
                <p><strong>Name:</strong> ${formDatatosend.name}</p>
                <p><strong>Date:</strong> ${dayjs(formData.date).format(
                  "dddd, MMMM , YYYY"
                )}</p>
                <p><strong>Time:</strong> ${formData.time}</p>
                <p><strong>Location:</strong> ${formData.location}</p>
                
                <p><strong>Doctor:</strong> ${formData.doctor}</p>
                
              </div>`,

        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/");
      // getData();
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error!",
        text: "There was an error submitting your booking.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleDateChange = async (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format("YYYY-MM-DD") : null; // Use null if no date is selected
    setSelectedDate(formattedDate);
    setSelectedTime("");
    handleTimeClick("")
    setFormData((prevData) => ({ ...prevData, date: formattedDate }));
    await getData(formattedDate);
    const bookings = await fetchExistingBookings(formattedDate);
    setExistingBookings(bookings.map((booking) => booking.time));
    setFormErrors((prevErrors) => ({ ...prevErrors, date: "" }));
  };
  const getDepartments = async (location) => {
    try {
      const response = await axios.get(`${BaseUrl}clinic/staff-list/`, {});
      setDepartments(response.data);
    } catch (error) {
      setError(error.message);
    }
  };
  const getLocation = async () => {
    try {
      const response = await axios.get(`${BaseUrl}clinic/managelocation/`, {});
      setLocation(response.data);
      console.log(response.data);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    const name = Cookies.get("name");
    if (!name) {
      navigate("/getdetails");
    }
    getLocation();
    getData(selectedDate);
    const storedData = localStorage.getItem("formData");
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, [selectedDate]);
  return (
    <>
      {loading ? (
        <LoaderH />
      ) : (
        <>
          <div>
            <div className="bg-[#F2EFEA] pt-6">
              <div className="container grid grid-cols-2 mx-auto px-4 sm:px-8 lg:px-32 xl:px-48">
                <div className="flex flex-col justify-center items-start text-black font-black text-lg sm:text-xl md:text-3xl lg:text-7xl">
                  Get your Slot
                </div>
                <div className="flex flex-col justify-center items-end">
                  <img src="/assets/Mainabout.png" alt="" />
                </div>
              </div>
            </div>

            <div className="container mx-auto px-4 sm:px-8 lg:px-32 xl:px-48 my-16">
              <div className="flex flex-col-reverse lg:flex-row mt-16">
                <div className="flex flex-col w-full lg:w-3/5 pr-6">
                  <label className="font-inter text-base font-black leading-6 text-left text-[#585858] mb-2 px-2">
                    Location<span className="text-red-500">*</span>
                  </label>
                  <select
                    name="location"
                    id="location"
                    className="h-[80px] w-full !px-4 !pr-6 rounded-[15px] bg-[#ffffff] border-2 border-gray-300 font-inter text-base font-normal leading-[24.2px] text-left"
                    onChange={handleChange}
                  >
                    <option value="">Select a Location</option>
                    {location.length > 0 ? (
                      location
                        .filter((location) => location.status === 1)
                        .map((location) => (
                          <option key={location.id} value={location.name}>
                            {location.name}
                          </option>
                        ))
                    ) : (
                      <option disabled>Loading...</option>
                    )}
                  </select>
                  <span className="text-red-500 ml-2 mt-2">
                    {formErrors.location}
                  </span>

                  <label className="font-inter text-base font-black leading-6 text-left text-[#585858] mb-2 px-2">
                    Department<span className="text-red-500">*</span>
                  </label>
                  <select
                    name="department"
                    id="department"
                    className="h-[80px] w-full !px-4 !pr-6 rounded-[15px] bg-[#ffffff] border-2 border-gray-300 font-inter text-base font-normal leading-[24.2px] text-left"
                    onChange={handleChange}
                  >
                    <option value="">Select a Department</option>
                    {departments.length > 0 ? (
                      Array.from(
                        new Set(
                          departments
                            .filter(
                              (department) =>
                                department.location === formData.location
                            )
                            .map((department) => department.department)
                        )
                      ).map((department) => {
                        const departmentData = departments.find(
                          (dep) =>
                            dep.department === department &&
                            dep.location === formData.location
                        );
                        return (
                          <option
                            key={departmentData.id}
                            value={departmentData.department}
                          >
                            {departmentData.department}
                          </option>
                        );
                      })
                    ) : (
                      <option disabled>Select Location First....</option>
                    )}
                  </select>
                  <span className="text-red-500 ml-2 mt-2">
                    {formErrors.department}
                  </span>

                  <label className="font-inter text-base font-black leading-6 text-left text-[#585858] mb-2 px-2">
                    Doctor<span className="text-red-500">*</span>
                  </label>
                  <select
                    name="doctor"
                    id="doctor"
                    className="h-[80px] w-full !px-4 rounded-[15px] bg-[#ffffff] border-2 border-gray-300 font-inter text-base font-normal leading-[24.2px] text-left"
                    onChange={handleChange}
                  >
                    <option value="">Select a Doctor</option>
                    {departments.length > 0 ? (
                      Array.from(
                        new Set(
                          departments
                            .filter(
                              (doctor) =>
                                doctor.department === formData.department
                            )
                            .map((doctor) => doctor.username) // Ensure `username` is used here
                        )
                      ).map((username) => {
                        const doctorData = departments.find(
                          (doc) =>
                            doc.username === username &&
                            doc.location === formData.location &&
                            doc.department === formData.department
                        );
                        return doctorData ? (
                          <option
                            key={doctorData.id}
                            value={doctorData.username}
                          >
                            {doctorData.fname + " " + doctorData.lname}
                          </option>
                        ) : null;
                      })
                    ) : (
                      <option disabled>
                        Select Location & Department First....
                      </option>
                    )}
                  </select>
                  <span className="text-red-500 ml-2 mt-2">
                    {formErrors.doctor}
                  </span>

                  <label className="font-inter text-base font-black leading-6 text-left text-[#585858] px-2 mt-8 mb-2">
                    Your Problem<span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="problem"
                    className="h-[151px] w-full px-4 py-2 rounded-[15px] bg-[#ffffff] border-2 border-gray-300 font-inter text-base font-normal leading-[24.2px]"
                    onChange={handleChange}
                  />
                  <span className="text-red-500 ml-2 mt-2">
                    {formErrors.problem}
                  </span>
                </div>
                <div className="w-full  lg:w-2/5">
                  <img
                    className="w-full h-5/6"
                    src="/assets/Booking/book2.png"
                    alt="Booking"
                  />
                </div>
              </div>

              <div className="flex justify-around mt-4 flex sm:flex-row flex-col sm:space-x-5 rtl:space-x-reverse">
                <div className="w-full flex flex-col p-2 items-center justify-center border-2 border-gray-300">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      className="!w-full lg:!w-1/2 scale-[1.01]"
                      variant="static"
                      orientation="portrait"
                      value={selectedDate ? dayjs(selectedDate).toDate() : null} // Convert to Date object if not null
                      disablePast
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      renderDay={(day, _value, DayComponentProps) => {
                        const isSelected =
                          !DayComponentProps.outsideCurrentMonth;
                        return (
                          <Badge
                            key={day.toString()}
                            overlap="circular"
                            badgeContent={
                              isSelected ? (
                                <CheckIcon className="text-red-900" />
                              ) : undefined
                            }
                          >
                            <PickersDay {...DayComponentProps} />
                          </Badge>
                        );
                      }}
                    />
                  </LocalizationProvider>
                  <span className="text-red-500 ml-[-150px] mt-3">
                    {formErrors.date}
                  </span>
                </div>
                <div className="sm:ms-7 sm:ps-5  w-full p-4  sm:mt-0 border-2 border-gray-300">
                  <h3 className="text-gray-900 dark:text-black text-lg font-medium mb-3 text-center">
                    {dayjs(selectedDate).format("dddd, MMMM D, YYYY")}
                  </h3>
                  <text className="flex font-medium  text-gray-500 border-b border-gray-400 !w-full justify-center">
                    Pick a Time
                  </text>
                  <div className="grid grid-cols-3 gap-3 mt-4">
                    {timeslots.length > 1 ? (
                      timeslots.map((item, index) => (
                        <h2
                          key={index}
                          className={`p-2 border rounded-full flex items-center justify-center cursor-pointer ${
                            existingBookings.includes(item.time)
                              ? "bg-gray-400 cursor-not-allowed"
                              : item.time === selectedTime
                              ? "bg-blue-500 text-white"
                              : "hover:bg-[#1976d2] hover:text-white"
                          }`}
                          onClick={() => handleTimeClick(item.time)}
                        >
                          {item.time}
                        </h2>
                      ))
                    ) : (
                      <p>No available slots.</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-3 mt-8 mb-4">
                    <div className="flex items-center">
                      <div className="bg-[#ffffff] border-[2px] rounded-lg border-black w-5 h-5 mr-2"></div>{" "}
                      :{" "}
                      <text className="ml-2 font-medium">Available Slots</text>
                    </div>
                    <div className="flex items-center">
                      <div className="bg-[#9ca3af] border-[2px] rounded-lg border-black w-5 h-5 mr-2"></div>{" "}
                      : <text className="ml-2 font-medium">Booked Slots</text>
                    </div>
                    <div className="flex items-center">
                      <div className="bg-blue-500 border-[2px] rounded-lg border-black w-5 h-5 mr-2"></div>{" "}
                      : <text className="ml-2 font-medium">Selected Slot</text>
                    </div>
                  </div>
                  <span className="text-red-500 ml-2 mt-3">
                    {formErrors.time}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex w-full gap-8 my-12 items-center justify-center container mx-auto px-4 sm:px-8 lg:px-32 xl:px-48">
              <button
                type="button"
                className="w-1/4 h-[60px] bg-[#F16163] text-[#ffffff] rounded-[20px] flex items-center justify-center font-inter text-[12px] md:text-[28px] font-medium leading-8 hover:bg-red-600"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <Link
                className="w-1/5 h-[60px] bg-[#1030A4] text-[#ffffff] rounded-[20px] flex items-center justify-center font-inter text-[12px] md:text-[28px] font-medium leading-8 hover:bg-blue-700"
                to="/"
              >
                Back
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Booking;
