import { useState, useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import AppointmentModal from "./appointmentmodal";
import axios from 'axios'
import BaseUrl from "../../Api/baseurl";
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [data, setData] = useState({
    new_logo : "",
  });
  const handleTabClick = (tab) => {
    setActiveTab(window.location.pathname);
  };

  const active = window.location.pathname;
  const fetchData = async () => {
    const apiUrl = `${BaseUrl}clinic/logochange/`;
    // const token = localStorage.getItem('auth_token') 
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          // Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
        setData(response.data,"data");
    } catch (error) {
        console.log('Error:', error);
    }
  }
  
  useEffect(() => {
    fetchData();
  },[])

  return (
    <div className="bg-gray-200">
      <div className="container mx-auto px-4 sm:px-8 lg:px-32 xl:px-48">
        <div className="flex items-center justify-between h-24 sm:h-32">
          {data &&(
          <div className='!w-1/2 md:!w-auto'>
            <Link to='/'><img src={data.new_logo} /></Link>
          </div>
          )}
          {/* Navigation links hidden on medium screens */}
          <nav className="hidden lg:flex flex-grow justify-center space-x-5">
            <Link to="/" onClick={() => handleTabClick('home')} className={`font-medium  ${activeTab === 'home' || active === '/' ? 'text-blue-800' : 'text-black'}`}>
              Home
            </Link>
            <Link to="/about" onClick={() => handleTabClick('about')} className={`font-medium  ${activeTab === 'about' || active === '/about' ? 'text-blue-800' : 'text-black'}`}>
              About Us
            </Link>
            <Link to="/services" onClick={() => handleTabClick('services')} className={`font-medium  ${activeTab === 'services' || active === '/services' ? 'text-blue-800' : 'text-black'}`}>
              Services
            </Link>
            <Link to="/blog" onClick={() => handleTabClick('blog')} className={`font-medium  ${activeTab === 'blog' || active === '/blog' ? 'text-blue-800' : 'text-black'}`}>
              Blog
            </Link>
            <Link to="/contactus" onClick={() => handleTabClick('contactus')} className={`font-medium  ${activeTab === 'contactus' || active === '/contactus' ? 'text-blue-800' : 'text-black'}`}>
              Contact Us
            </Link>
          </nav>
          <div className="hidden lg:flex flex-shrink-0">
            <Link
              type="button"
              className="py-2 px-3 bg-[#1030A4] text-white rounded-[5px] hover:bg-blue-700 font-semibold"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Book an Appointment
            </Link>
          </div>
          <AppointmentModal />
          {/* Menu button visible on medium and small screens */}
          <div className="lg:hidden flex-shrink-0">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="text-xl text-gray-700 hover:text-gray-900 focus:outline-none mr-4"
            >
              {showMenu ? <GrClose /> : <GiHamburgerMenu />}
            </button>
          </div>
        </div>
        {/* Mobile menu dropdown */}
        {showMenu && (
          <div className="lg:hidden bg-gray-200 shadow-md py-2 px-4">
            <nav className="flex flex-col space-y-2">
              
              <Link to="/" onClick={() => handleTabClick('home')} className={`font-medium  ${activeTab === 'home' || active === '/' ? 'text-blue-800' : 'text-black'}`}>
                Home
              </Link>
              <Link to="/about" onClick={() => handleTabClick('about')} className={`font-medium  ${activeTab === 'about' || active === '/about' ? 'text-blue-800' : 'text-black'}`}>
                About Us
              </Link>
              <Link to="/services" onClick={() => handleTabClick('services')} className={`font-medium  ${activeTab === 'services' || active === '/services' ? 'text-blue-800' : 'text-black'}`}>
                Services
              </Link>
              <Link to="/blog" onClick={() => handleTabClick('blog')} className={`font-medium  ${activeTab === 'blog' || active === '/blog' ? 'text-blue-800' : 'text-black'}`}>
                Blog
              </Link>
              <Link to="/contactus" onClick={() => handleTabClick('contactus')} className={`font-medium  ${activeTab === 'contactus' || active === '/contactus' ? 'text-blue-800' : 'text-black'}`}>
                Contact Us
              </Link>


              <button
                type="button"
                className="py-2 px-3 bg-[#1030A4] text-white rounded-[5px] hover:bg-blue-700 font-semibold text-center"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Book an Appointment
              </button>
            </nav>

          </div>

        )}
      </div>

    </div>
  );
};

export default Header;
