import React, { useState, useEffect } from "react";
import AdminSearch from "../../../Component/Admin/adminsearch";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import BaseUrl from "../../../Api/baseurl";
import dayjs from "dayjs";
import DoctorSearch from "../../../Component/Doctor/doctorsearch";

const AddAppointment = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    age: "",
    contact: "",
    email: "",
    city: "",
    location: "",
    date: "",
    time: "",
    gender: "",
    department: "",
    doctor: "",
    problem: "",
    username: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [slots, setSlots] = useState({});
  const [timeslots, setTimeslots] = useState([]);
  const [existingBookings, setExistingBookings] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [location, setLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [doctor, setDoctor] = useState("");
  

  useEffect(() => {
    getLocation();
    if (selectedDate) {
      getData(selectedDate);
      fetchExistingBookings(selectedDate).then((bookings) =>
        setExistingBookings(bookings)
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    
    if (formData.location) {
      getDepartments(formData.location);
    } else {
      setDepartments([]);
    }
  }, [formData.location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    if (name === "doctor") {
      handleDoctorChange(value);
    }
  };

  const handleDoctorChange = async (username) => {
    const doctorData = departments.find((doc) => doc.username === username);
    if (doctorData) {
      setFormData((prev) => ({
        ...prev,
        doctor: `${doctorData.fname} ${doctorData.lname}`,
        username: doctorData.username,
        date: "",
        time: "",
      }));
      setDoctor(doctorData.username);
      setSelectedTime("");
      setSelectedDate(null)
      await fetchExistingBookings(dayjs().format("YYYY-MM-DD"));
    }
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "name",
      "age",
      "contact",
      "email",
      "city",
      "location",
      "department",
      "doctor",
      "date",
      "time",
      "gender",
      "problem",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]?.trim()) {
        errors[field] = `Please enter the ${field}.`;
      }
    });

    if (formData.email && !isValidEmail(formData.email.trim())) {
      errors.email = "Please enter a valid email address.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        try {
          await axios.post(`${BaseUrl}clinic/submit-appointment/`, formData, {
            headers: { "Content-Type": "application/json" },
          });
          navigate("/admin/appointments");
          Swal.fire({
            title: "Success!",
            text: "Appointment added successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `There was an issue adding the appointment: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    }
  };

  const getLocation = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get(`${BaseUrl}clinic/managelocation/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setLocation(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const getDepartments = async (location) => {
    const token = Cookies.get("token");
    try {
      const response = await axios.get(`${BaseUrl}clinic/staff-list/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setDepartments(response.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const getData = async (slotdate) => {
    const apiUrl = `${BaseUrl}clinic/slots/${doctor}/${slotdate}`;
    const token = Cookies.get("token");
    setLoading(true);
    try {
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Token ${token}` },
      });
      const info = response.data;
      if (info.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "Not Available",
          text: "No slots available on this date",
        });
        setSlots({});
        setTimeslots([]);
      } else {
        setSlots(info[0]);
        generateTimeSlots(info[0]);
        fetchExistingBookings(slotdate);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const generateTimeSlots = (slotsData) => {
    const timeList = [];
    const {
      duration,
      morningS,
      morningE,
      afternoonS,
      afternoonE,
      eveningS,
      eveningE,
    } = slotsData;

    const addTimeSlots = (start, end) => {
      let currentTime = dayjs(start, "HH:mm");
      const endTime = dayjs(end, "HH:mm");
      while (currentTime.isBefore(endTime)) {
        timeList.push({ time: currentTime.format("HH:mm"), booked: false });
        currentTime = currentTime.add(duration, "minute");
      }
    };

    if (morningS && morningE) addTimeSlots(morningS, morningE);
    if (afternoonS && afternoonE) addTimeSlots(afternoonS, afternoonE);
    if (eveningS && eveningE) addTimeSlots(eveningS, eveningE);

    timeList.forEach((slot) => {
      if (existingBookings.includes(slot.time)) {
        slot.booked = true;
      }
    });

    setTimeslots(timeList);
  };

  const handleDateChange = (e) => {
    const formattedDate = e.target.value;
    setSelectedDate(formattedDate);
    setSelectedTime("");
    setFormData((prevData) => ({ ...prevData, date: formattedDate }));
    setFormErrors((prevErrors) => ({ ...prevErrors, date: "" }));
    fetchExistingBookings(formattedDate);
  };

  const handleTimeClick = (time) => {
    if (existingBookings.includes(time)) return;
    setSelectedTime(time);
    setFormData((prev) => ({ ...prev, time }));
    setFormErrors((prevErrors) => ({ ...prevErrors, time: "" }));
  };

  const fetchExistingBookings = async (date) => {
    const apiUrl = `${BaseUrl}clinic/booking/${doctor}`;
    try {
      const response = await axios.get(apiUrl);
      const bookings = response.data.filter((booking) => booking.date === date);
      return bookings.map((booking) => booking.time);
    } catch (error) {
      setError(error.message);
      return [];
    }
  };

  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  useEffect(()=> {
    const superuser = Cookies.get("is_superuser") === "true";
    const staff = Cookies.get("is_staff") === "true";
    setIsSuperuser(superuser);
    setIsStaff(staff);
  },[])
  return (
    <div className="py-8 px-8 w-full md:w-[80%] xl:w-full">
      {isSuperuser ? <AdminSearch /> : <DoctorSearch />}
      <div className="w-full bg-[#F2F2F2] px-4 py-8 mt-3">
        <div className="flex items-center justify-between">
          <text className="font-nunito-sans text-[32px] font-bold leading-[43.65px] text-[#202224]">
            Add Appointment
          </text>
        </div>
        <form id="AddAppointment" onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="pb-12">
              <div className="mt-10 grid grid-cols-6 gap-x-6 gap-y-8 ">
                {[
                  { id: "name", label: "Name", type: "text", span: 3 },
                  { id: "age", label: "Age", type: "text", span: 3 },
                  { id: "contact", label: "Contact", type: "text", span: 3 },
                  {
                    id: "email",
                    label: "Email address",
                    type: "text",
                    span: 3,
                  },
                  { id: "city", label: "City", type: "text", span: 3 },
                ].map(({ id, label, type, span }) => (
                  <div key={id} className={`col-span-full sm:col-span-3`}>
                    <label
                      htmlFor={id}
                      className="block text-md font-medium leading-6 text-gray-900"
                    >
                      {label}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-2">
                      {type === "textarea" ? (
                        <textarea
                          id={id}
                          name={id}
                          value={formData[id]}
                          onChange={handleChange}
                          autoComplete={id}
                          className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#B18D65] sm:text-sm sm:leading-6"
                        />
                      ) : (
                        <input
                          type={type}
                          id={id}
                          name={id}
                          value={formData[id]}
                          onChange={handleChange}
                          autoComplete={id}
                          className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#B18D65] sm:text-sm sm:leading-6"
                        />
                      )}
                      {formErrors[id] && (
                        <p className="mt-2 text-sm text-red-600">
                          {formErrors[id]}
                        </p>
                      )}
                    </div>
                  </div>
                ))}

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender<span className="text-red-500">*</span>
                  </label>{" "}
                  <div className="mt-2">
                    <select
                      id="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    {formErrors.gender && (
                      <p className="text-red-600">{formErrors.gender}</p>
                    )}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Location<span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    {/* <select
                      id="gender"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select> */}
                    <select
                      id="location"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Select a location</option>
                      {location.length > 0 ? (
                        location
                          .filter((location) => location.status === 1) // Filter locations by status
                          .map((location) => (
                            <option key={location.id} value={location.name}>
                              {location.name}
                            </option>
                          ))
                      ) : (
                        <option disabled>Loading...</option>
                      )}
                    </select>
                    {formErrors.location && (
                      <p className="text-red-600">{formErrors.location}</p>
                    )}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="department"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Department<span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <select
                      id="department"
                      name="department"
                      value={formData.department}
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="">Select a department</option>
                      {departments.length > 0 ? (
                        Array.from(
                          new Set(
                            departments
                              .filter(
                                (department) =>
                                  department.location === formData.location
                              )
                              .map((department) => department.department)
                          )
                        ).map((department) => {
                          const departmentData = departments.find(
                            (dep) =>
                              dep.department === department &&
                              dep.location === formData.location
                          );
                          return (
                            <option
                              key={departmentData.id}
                              value={departmentData.department}
                            >
                              {departmentData.department}
                            </option>
                          );
                        })
                      ) : (
                        <option disabled>Select Location First...</option>
                      )}
                    </select>

                    {formErrors.department && (
                      <p className="text-red-600">{formErrors.department}</p>
                    )}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="doctor"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Doctor<span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <select
                      name="doctor"
                      id="doctor"
                      className="block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleChange}
                    >
                      <option value="">Select a Doctor</option>
                      {departments.length > 0 ? (
                        Array.from(
                          new Set(
                            departments
                              .filter(
                                (doctor) =>
                                  doctor.department === formData.department
                              )
                              .map((doctor) => doctor.username) // Ensure `username` is used here
                          )
                        ).map((username) => {
                          const doctorData = departments.find(
                            (doc) =>
                              doc.username === username &&
                              doc.location === formData.location &&
                              doc.department === formData.department
                          );
                          return doctorData ? (
                            <option
                              key={doctorData.id}
                              value={doctorData.username}
                            >
                              {doctorData.fname + " " + doctorData.lname}
                            </option>
                          ) : null;
                        })
                      ) : (
                        <option disabled>
                          Select Location & Department First....
                        </option>
                      )}
                    </select>
                    {formErrors.doctor && (
                      <p className="text-red-600">{formErrors.doctor}</p>
                    )}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="date"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Date
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      id="date"
                      name="date"
                      min={dayjs().format("YYYY-MM-DD")}
                      value={formData.date}
                      onChange={handleDateChange}
                      className="block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#B18D65] sm:text-sm sm:leading-6"
                    />
                    {formErrors.date && (
                      <p className="mt-2 text-sm text-red-600">
                        {formErrors.date}
                      </p>
                    )}
                  </div>
                </div>

                <div key={"problem"} className={`col-span-full`}>
                  <label
                    htmlFor={"problem"}
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Problem Description
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="problem"
                      name="problem"
                      value={formData.problem}
                      onChange={handleChange}
                      autoComplete="problem"
                      rows={4}
                      className="block w-full rounded-md border-0 pl-3 pr-12 py-1.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formErrors.problem && (
                      <p className="mt-2 text-sm text-red-600">
                        {formErrors.problem}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <label
                    htmlFor="time"
                    className="block text-md font-medium leading-6 text-gray-900"
                  >
                    Time
                    <span className="text-red-500">*</span>
                  </label>
                  {/* <div className="mt-2">
                    <div className="grid grid-cols-3 gap-2">
                      {timeslots.map(({ time, booked }, index) => (
                        <button
                          key={index}
                          type="button"
                          onClick={() => handleTimeClick(time)}
                          disabled={booked}
                          className={`w-full rounded-md px-3 py-2 text-sm font-semibold leading-6 ring-1 ring-gray-300 ${
                            (booked
                              && "!bg-gray-400 !text-gray-700 !cursor-not-allowed")
                              || ( selectedTime === time
                              ? "bg-blue-500 text-white ring-blue-500"
                              : "bg-white text-gray-900 hover:ring-blue-500")
                          }`}
                        >
                          {time}
                        </button>
                      ))}
                    </div>
                    {formErrors.time && (
                      <p className="mt-2 text-sm text-red-600">
                        {formErrors.time}
                      </p>
                    )}
                  </div> */}

                  <div className="mt-2">
                    <div className="grid grid-cols-3 gap-2">
                      {timeslots.map(({ time, booked }, index) => {
                        // Determine the button class names
                        const baseClasses =
                          "w-full rounded-md px-3 py-2 text-sm font-semibold leading-6 ring-1 ring-gray-300";
                        const bookedClasses =
                          "bg-gray-400 text-gray-700 cursor-not-allowed";
                        const selectedClasses =
                          "bg-blue-500 text-white ring-blue-500";
                        const defaultClasses =
                          "bg-white text-gray-900 hover:ring-blue-500";

                        // Compute button class based on state
                        const buttonClasses = `
                           ${baseClasses} 
                           ${
                             booked
                               ? bookedClasses
                               : selectedTime === time
                               ? selectedClasses
                               : defaultClasses
                           }
                         `;

                        return (
                          <button
                            key={index}
                            type="button"
                            onClick={() => handleTimeClick(time)}
                            disabled={booked}
                            className={buttonClasses.trim()} // Ensure no extra whitespace
                          >
                            {time}
                          </button>
                        );
                      })}
                    </div>
                    {formErrors.time && (
                      <p className="mt-2 text-sm text-red-600">
                        {formErrors.time}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4 flex items-center justify-center w-full gap-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
              <Link
                to="/admin/appointments"
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAppointment;
