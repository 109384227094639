import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import CheckIcon from "@mui/icons-material/Check";
import AdminSearch from "../../Component/Admin/adminsearch";
import DoctorSearch from "../../Component/Doctor/doctorsearch";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import BaseUrl from "../../Api/baseurl";
import { Link, useNavigate } from "react-router-dom";

dayjs.extend(customParseFormat);

const ManageSlots = () => {
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [times, setTimes] = useState({});
  const [timeslots, setTimesslots] = useState([]);
  const [generateForSevenDays, setGenerateForSevenDays] = useState(false); 

  const [saved, setSaved] = useState(false);
  const [existingBookings, setExistingBookings] = useState([]);
  const navigate = useNavigate();
  const user = Cookies.get("username");
  const fetchData = async () => {
    console.log("Fetching data for date:", selectedDate);
    try {
      const response = await axios.get(
        `${BaseUrl}clinic/slots/${user}/${selectedDate}`
      );
      const slotData = response.data.length > 0 ? response.data[0] : null;
      console.log("Fetched data:", slotData, selectedDate);
      gettime(selectedDate);
      if (slotData !== null) {
        setSaved(true);
      } else {
        setSaved(false);
      }

      setTimes((prevTimes) => ({
        ...prevTimes,
        [selectedDate]: slotData
          ? {
              id: slotData.id,
              morningStart: dayjs(slotData.morningS, "HH:mm"),
              morningEnd: dayjs(slotData.morningE, "HH:mm"),
              afternoonStart: dayjs(slotData.afternoonS, "HH:mm"),
              afternoonEnd: dayjs(slotData.afternoonE, "HH:mm"),
              eveningStart: dayjs(slotData.eveningS, "HH:mm"),
              eveningEnd: dayjs(slotData.eveningE, "HH:mm"),
              duration: slotData.duration,
            }
          : {
              id: null,
              morningStart: dayjs("08:00", "HH:mm"),
              morningEnd: dayjs("10:00", "HH:mm"),
              afternoonStart: dayjs("14:00", "HH:mm"),
              afternoonEnd: dayjs("16:00", "HH:mm"),
              eveningStart: dayjs("19:00", "HH:mm"),
              eveningEnd: dayjs("21:00", "HH:mm"),
              duration: 15,
            },
      }));
      // gettime(selectedDate);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.code === "ERR_BAD_REQUEST") {
        Swal.fire({
          icon: "warning",
          title: "Session expired. Please login again.",
        });
        Cookies.remove("token");
        Cookies.remove("username");
        Cookies.remove("is_superuser");
        Cookies.remove("is_staff");
        Cookies.remove("status");
        navigate("/admin/login");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  useEffect(() => {
    if (times[selectedDate]) {
      gettime(selectedDate);
    }
  }, [times[selectedDate]]);

  const handleDateChange = async (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
    console.log("Selected date changed to:", formattedDate);
    gettime(formattedDate);
    if (!times[formattedDate]) {
      setTimes((prevTimes) => ({
        ...prevTimes,
        [formattedDate]: {
          morningStart: dayjs("00:00", "HH:mm"),
          morningEnd: dayjs("00:00", "HH:mm"),
          afternoonStart: dayjs("00:00", "HH:mm"),
          afternoonEnd: dayjs("00:00", "HH:mm"),
          eveningStart: dayjs("00:00", "HH:mm"),
          eveningEnd: dayjs("00:00", "HH:mm"),
          duration: 0,
        },
      }));
    }
    setSelectedDate(formattedDate);
    gettime(formattedDate);
    const bookings = await fetchExistingBookings(formattedDate);
    setExistingBookings(bookings.map((booking) => booking.time));
  };
  const fetchExistingBookings = async (date) => {
    const apiUrl = `${BaseUrl}clinic/booking/${user}`;
    // const token = Cookies.get('token');
    try {
      const response = await axios.get(apiUrl, {
        // headers: { Authorization: `Token ${token}` },
      });
      console.log(response.data.filter((booking) => booking.date === date));
      return response.data.filter((booking) => booking.date === date);
    } catch (error) {
      // setError(error);
      console.error(error);
      return [];
    }
  };

  const handleTimeChange = (period, type, value) => {
    const newValue = value
      ? dayjs(value.format("HH:mm"), "HH:mm")
      : dayjs("00:00", "HH:mm");
    console.log(`Time changed - ${period}${type}:`, newValue.format("HH:mm"));

    setTimes((prevTimes) => ({
      ...prevTimes,
      [selectedDate]: {
        ...prevTimes[selectedDate],
        [`${period}${type}`]: newValue,
      },
    }));
  };

  const handleDurationChange = (event) => {
    const newDuration = parseInt(event.target.value, 10) || 0;
    console.log("Duration changed to:", newDuration);

    setTimes((prevTimes) => ({
      ...prevTimes,
      [selectedDate]: {
        ...prevTimes[selectedDate],
        duration: newDuration,
      },
    }));
    gettime(selectedDate);
  };

  const gettime = (selectedDate) => {
    const currentTimes = times[selectedDate];
    if (!currentTimes) return;

    const timeList = [];
    const duration = currentTimes.duration;
    const addTimeSlots = (start, end) => {
      for (
        let time = start;
        time.isBefore(end);
        time = time.add(duration, "minute")
      ) {
        timeList.push({ time: time.format("HH:mm") });
      }
    };
    console.log(timeList, "timeList");

    addTimeSlots(currentTimes.morningStart, currentTimes.morningEnd);
    addTimeSlots(currentTimes.afternoonStart, currentTimes.afternoonEnd);
    addTimeSlots(currentTimes.eveningStart, currentTimes.eveningEnd);

    setTimesslots(timeList);
  };

  const handleSave = async () => {
    const currentTimes = times[selectedDate];
    const duration = currentTimes?.duration || 0;
    const id = currentTimes?.id;

    if (duration <= 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Duration is required and must be greater than 0",
      });
      return;
    }

    const data = {
      date: selectedDate,
      morningS: currentTimes?.morningStart.format("HH:mm"),
      morningE: currentTimes?.morningEnd.format("HH:mm"),
      afternoonS: currentTimes?.afternoonStart.format("HH:mm"),
      afternoonE: currentTimes?.afternoonEnd.format("HH:mm"),
      eveningS: currentTimes?.eveningStart.format("HH:mm"),
      eveningE: currentTimes?.eveningEnd.format("HH:mm"),
      duration: currentTimes?.duration || 0,
      username: user,
    };

    console.log("Saving data:", data);

    try {
      if (id) {
        // If ID exists, update using PUT
        await axios.put(`${BaseUrl}clinic/slot/${id}/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: "Your data has been updated successfully.",
        });
        fetchData();
      } else {
        // If no ID, create a new slot using POST
        await axios.post(
          `${BaseUrl}clinic/slots/${user}/${selectedDate}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        Swal.fire({
          icon: "success",
          title: "Saved!",
          text: "Your data has been saved successfully.",
        });
        gettime();
      }
    } catch (error) {
      console.error("Error saving data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error saving your data.",
      });
    }
  };

  return (
    <div className="py-8 px-8 w-full md:w-[80%] xl:w-full">
      {/* <AdminSearch /> */}
      <DoctorSearch />
      <div className="w-full bg-[#F2F2F2] px-4 py-8 mt-3">
      <div className="flex items-center justify-between pb-4">
          <text className="font-nunito-sans text-[32px] font-bold leading-[43.65px] text-[#202224]">
            Manage Slots
          </text>
          <Link
            to="/doctor/manageslots/settings"
            className="font-nunito-sans text-14px font-bold leading-27px text-[#ffffff] bg-[#4379EE] py-3 px-4 rounded-lg text-center"
          >
            Settings
          </Link>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 mt-4">
          <div className="flex justify-center w-full lg:w-[75%] xl:w-full mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                className="w-full max-w-[30rem]"
                variant="static"
                orientation="portrait"
                value={dayjs(selectedDate)}
                disablePast
                onChange={(newValue) => handleDateChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
                renderDay={(day, _value, DayComponentProps) => {
                  const isSelected = !DayComponentProps.outsideCurrentMonth;
                  return (
                    <Badge
                      key={day.toString()}
                      overlap="circular"
                      badgeContent={
                        isSelected ? (
                          <CheckIcon className="text-red-900" />
                        ) : undefined
                      }
                    >
                      <PickersDay {...DayComponentProps} />
                    </Badge>
                  );
                }}
              />
            </LocalizationProvider>
          </div>

          <form className="max-w-[28rem] flex flex-col mx-auto gap-4 py-10">
            {/* Morning */}
            <div className="flex flex-col">
              <label className="font-bold">Morning:</label>
              <div className="flex flex-col sm:flex-row gap-8 mt-2">
                <div className="flex items-center">
                  Start:{" "}
                  <TimePicker
                    className="min-w-24 ml-2"
                    format="HH:mm"
                    value={times[selectedDate]?.morningStart || null}
                    onChange={(value) =>
                      handleTimeChange("morning", "Start", value)
                    }
                  />
                </div>
                <div className="flex items-center">
                  End:{" "}
                  <TimePicker
                    className="min-w-24 ml-2"
                    format="HH:mm"
                    value={times[selectedDate]?.morningEnd || null}
                    onChange={(value) =>
                      handleTimeChange("morning", "End", value)
                    }
                  />
                </div>
              </div>
            </div>

            {/* Afternoon */}
            <div className="flex flex-col">
              <label className="font-bold">Afternoon:</label>
              <div className="flex flex-col sm:flex-row gap-8 mt-2">
                <div className="flex items-center">
                  Start:{" "}
                  <TimePicker
                    className="min-w-24 ml-2"
                    format="HH:mm"
                    value={times[selectedDate]?.afternoonStart || null}
                    onChange={(value) =>
                      handleTimeChange("afternoon", "Start", value)
                    }
                  />
                </div>
                <div className="flex items-center">
                  End:{" "}
                  <TimePicker
                    className="min-w-24 ml-2"
                    format="HH:mm"
                    value={times[selectedDate]?.afternoonEnd || null}
                    onChange={(value) =>
                      handleTimeChange("afternoon", "End", value)
                    }
                  />
                </div>
              </div>
            </div>

            {/* Evening */}
            <div className="flex flex-col">
              <label className="font-bold">Evening:</label>
              <div className="flex flex-col sm:flex-row gap-8 mt-2">
                <div className="flex items-center">
                  Start:{" "}
                  <TimePicker
                    className="min-w-24 ml-2"
                    format="HH:mm"
                    value={times[selectedDate]?.eveningStart || null}
                    onChange={(value) =>
                      handleTimeChange("evening", "Start", value)
                    }
                  />
                </div>
                <div className="flex items-center">
                  End:{" "}
                  <TimePicker
                    className="min-w-24 ml-2"
                    format="HH:mm"
                    value={times[selectedDate]?.eveningEnd || null}
                    onChange={(value) =>
                      handleTimeChange("evening", "End", value)
                    }
                  />
                </div>
              </div>
            </div>

            {/* Duration */}
            <div className="flex flex-col">
              <div className="flex items-center">
                <label htmlFor="duration" className="font-bold">
                  Duration:
                </label>
                <div className="flex items-center w-auto">
                  <select
                    className="w-[120px] bg-white rounded border-[1px] border-gray-300 sm:w-auto mx-2 px-2 py-1"
                    value={times[selectedDate]?.duration || "0"}
                    onChange={handleDurationChange}
                    name="duration"
                    id="duration"
                  >
                    <option value="0">Please Select...</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>{" "}
                  mins.
                </div>
              </div>
            </div>
            {saved ? (
              <button
                type="button"
                onClick={handleSave}
                className="w-full py-2 text-white font-bold text-sm bg-[#4379EE] rounded-lg hover:bg-[#3D66C8]"
              >
                Update
              </button>
            ) : (
              <button
                type="button"
                onClick={handleSave}
                className="w-full py-2 text-white font-bold text-sm bg-[#4379EE] rounded-lg hover:bg-[#3D66C8]"
              >
                Save
              </button>
            )}
          </form>
        </div>

        {/* Time Slots */}
        <div className="flex justify-between items-center mt-5 ">
          <div>
            <h2 className="text-gray-900 dark:text-black text-lg font-medium ml-1 text-center">
              Time Slots:
            </h2>
          </div>
          <div className="flex gap-4">
            <div className="flex items-center">
              <div className="bg-blue-500 border-[2px] rounded-lg border-black w-5 h-5 mr-2"></div>{" "}
              : <text className="ml-2 font-medium">Available slots</text>
            </div>
            <div className="flex items-center">
              <div className="bg-[#9ca3af] border-[2px] rounded-lg border-black w-5 h-5 mr-2"></div>{" "}
              : <text className="ml-2 font-medium">Booked slots</text>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-3 mt-3 bg-[#ffffff] border-[1px] rounded-lg border-black p-4">
          {/* <h2 className="p-2 border rounded-full flex items-center justify-center">Time Slot</h2> */}

          {saved ? (
            timeslots.map((item, index) => (
              <h2
                key={index}
                className={`p-2 border rounded-full flex items-center justify-center cursor-pointer ${
                  existingBookings.includes(item.time)
                    ? "bg-gray-400 cursor-not-allowed"
                    : item.time
                    ? "bg-blue-500 text-white"
                    : "hover:bg-[#1976d2] hover:text-white"
                }`}
                // onClick={() => handleTimeClick(item.time)}
              >
                {item.time}
              </h2>
            ))
          ) : (
            <p>No available slots.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageSlots;
